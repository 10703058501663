<template>
    <div>
        <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
            <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
        <v-row>
            <div style="position: fixed; z-index: 1;  background-color: white; width: 100%; top:55px;">
                <v-breadcrumbs :items="items" large style=" font-weight: 600; letter-spacing: 1px; ">

                </v-breadcrumbs>
            </div>
        </v-row><br><br><br />
       

        <div v-if="userid">
    
            <paid_fine :key="paid_fine_key" :userid="userid" 
            ></paid_fine>

          

        </div>

      



       




    </div>
</template>
<script>
import axios from "axios";
import paid_fine from './paid_fine.vue';

export default {
    components: {
        paid_fine,
   
    },
    data() {
        return {
            userid:"",
            color: "",
            snackbar: false,
            snackbar_msg: "",
          
            items: [
                {
                    text: 'Dashboard',
                    disabled: false,
                    href: '/home',
                },
                {
                    text: localStorage.getItem("jhhjbyj"),
                    disabled: false,
                    href: '/sublink',
                },
                {
                    text: 'Paid Fine',
                    disabled: true,
                    href: 'breadcrumbs_link_2',
                },
            ],
        };
    },
    mounted() {
     this.userid=localStorage.getItem("user_id");
    },
    methods: {
      
      
   
        showSnackbar(clr, msg) {
            this.snackbar = true;
            this.color = clr;
            this.snackbar_msg = msg;
        },
    },
};
</script>
