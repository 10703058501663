<template>
    <div>
        <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
            <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
       
        <v-card v-if="Array.isArray(receipt_list) && receipt_list.length" class="ma-3">
            <v-card-title> Paid Fine </v-card-title>
        <v-simple-table style="border:none;" >
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">
            Srno
          </th>
          <th class="text-left">
            Receipt No
          </th>
          <th class="text-left">
            View
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item,index) in receipt_list"
          :key="index"
        >
          <td>{{ index+1 }}</td>
          <td>{{ item }}</td>
          <td><v-icon @click="open_receipt_dialog(item)" color="green">mdi mdi-receipt-text-check</v-icon></td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
  </v-card>

  <v-dialog v-model="receipt_dialog">
            <v-card >     
                 <receipt_main :key="receipt_main_key" :recno="selected_receipt_no" 
                 ></receipt_main>
         </v-card>
                         </v-dialog>
     
    </div>
  </template>
  
  <script>
  import axios from "axios";
  import receipt_main from "./receipt_main.vue";
  export default {
    props: {
        userid: {
        required: true
      },
     
    },
    components: {
        receipt_main,
    },
    mounted() {
        this.init();
    },
  
    data() {
        return {
            receipt_main_key:"",
            receipt_dialog:false,
            selected_receipt_no:"",
            color: "",
            snackbar: false,
            snackbar_msg: "",
            receipt_list:[],
        }
    },
    methods: {
        open_receipt_dialog(value)
        {
            this.receipt_main_key++;
            this.receipt_dialog=true;
            this.selected_receipt_no=value;
        },
        showSnackbar(clr, msg) {
            this.snackbar = true;
            this.color = clr;
            this.snackbar_msg = msg;
        },
        init() {
            var params = { userid: this.userid, };
            this.overlay = true;
            axios
                .post("/IssueBook/getOldReceipt",params)
                .then((res) => {
                    if (res.data.msg == "200") {
                        this.overlay = false;
                        this.receipt_list = res.data.receipt_list;
                    }
                })
               
        },
    }
  }
  </script>
  
  <style scoped>
  /* Add your styles here */
  </style>